import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Logo from '../image/canary.png';

const Masthead = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const loginMenuOpen = Boolean(anchorEl);
    const handleLoginMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleLoginClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        handleLoginClose();
        props.appContext.IsAuthenticated = false;
        props.appContext.UserEmail = '';
        window.location = props.LogoutURI;
    };

    return (
        <div style={{width: "100%"}}> 
            {false /*window.user == null || window.user.data == null || window.user.data.user_login == null*/ ?
            <div style={{width: "100%"}}>
                <Button color="inherit" href={props.RedirectURI}
                    style={{ fontSize: '20px', textAlign: 'center', border: 'solid', borderWidth: '3px', paddingLeft: '2em', paddingRight: '2em' }} >
                    Sign In
                </Button>
            </div>
            :
            <div style={{width: "100%"}}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexGrow: 1 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',  width: '30%', maxWidth: '30%', padding: '2em', flexGrow: 1 }}>
                        <Typography variant="h6">
                            {'Application'}
                        </Typography>
                        <Typography variant="subtitle2" display="block" >
                            {'Data'}
                        </Typography>
                        <Typography variant="subtitle2" display="block" >
                            {'Testing'}
                        </Typography>
                    </div>
                    <img src={Logo} style={{ height: '120px', width: '154px', maxWidth: '154px', flexGrow: 1 }} alt='' />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', width: '30%', maxWidth: '30%', padding: '1em', fontSize: '36px', flexGrow: 1 }}>
                    <Button
                            onClick={handleLoginMenu}
                        >
                            <AccountCircle />
                            &nbsp;&nbsp;&nbsp;
                                <Typography variant="subtitle1" noWrap>
                                    {'User' /*window.user.data.user_login*/}
                                </Typography>
                        </Button>

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={loginMenuOpen}
                            onClose={handleLoginClose}
                        >
                            {false ?
                                <MenuItem onClick={handleLoginClose}>{'props.ViewSignInOrUp.model.Attrs.Name.Value'}</MenuItem>
                                :
                                ''
                            }
                            <MenuItem onClick={handleLogout} >Sign off</MenuItem>
                        </Menu>

                    </div>
                </div>
            </div>
        } </div>
    );
}
    
export default Masthead;
