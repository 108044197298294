import React, { useState, useEffect  } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import {
    Box,
    Button,
} from '@mui/material';

const ContentList = (props) => {
    //data and fetching state
    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    
    const columns = props.Columns;
    const columnsDrilldown = props.DrilldownMenuItem != null && 
		window.appContext.MenuItems.find(cur => cur.Id === props.DrilldownMenuItem) != null ?
        window.appContext.MenuItems.find(cur => cur.Id === props.DrilldownMenuItem).Columns : columns;
    let initialState = {
        showColumnFilters: true,
        showGlobalFilter: true,
        pagination: { pageSize: 25, pageIndex: 0 },
        columnVisibility: {}
    };
    columns.forEach(columnCur => {
        if (columnCur.Format.Visibility != null && columnCur.Format.Visibility === 'Hidden') {
            initialState.columnVisibility[columnCur.accessorKey] = false
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }
            let crudInfo = {
                Action: 'FetchRows',
                List: props.DataAnchor.Id ?? '',
                ParentId: props.ParentId ?? '',
                Filters: encodeURIComponent(JSON.stringify(columnFilters ?? [])),
                GlobalFilter: globalFilter ?? ''
            };
            //console.log("useEffect: ", JSON.stringify(crudInfo).substring(0, 500));
            const json = await window.appContext.transmitter.doCRUD(crudInfo);

            let dataLocal = json.Response.data;
            setRowCount(dataLocal.length);
            setData(dataLocal);
            //console.log("useEffect: ", JSON.stringify(dataLocal).substring(0, 80000));
            
            setIsError(false);
            setIsLoading(false);
            setIsRefetching(false);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
    ]);

    const table = useMaterialReactTable({
        columns,
        data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        enableFacetedValues: true,
        renderTopToolbarCustomActions: ({ table }) => (
            <Box>
                <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                    <Button
                        color="secondary"
                        onClick={
                            (e) => {
                                e.preventDefault();
                                let keyOffset = props.DataAnchor.KeyOffset != null ? props.DataAnchor.KeyOffset : 1;
                                let itemPushed = {
                                    Id: null, 
                                    Label: 'New', 
                                    KeyOffset: keyOffset,
                                    Data: data, 
                                    CrumbStackSegs: [],
                                    Shape: 'Form',
                                    Columns: columnsDrilldown,
                                    Row: null,
                                    List: props.DataAnchor.Id ?? '',
                                    ParentId: props.DataAnchor.ParentId ?? ''
                                };
                                props.SetStackSegs(prevStackSegs => {
                                    return [...prevStackSegs, itemPushed]
                                });
                            }
                        }
                        variant="contained"
                    >
                        Create New
                    </Button>
                </Box>
            </Box>
        ),
        initialState,
        muiTableBodyRowProps: ({ row }) => ({
            onClick: (e) => {
                e.preventDefault();
                let keyOffset = props.DataAnchor.KeyOffset != null ? props.DataAnchor.KeyOffset : 1;
                let itemPushed = {
                    Id: row.original[props.Columns[keyOffset].accessorKey], 
                    Label: row.original[props.Columns[keyOffset].accessorKey], 
                    KeyOffset: keyOffset,
                    Data: data, 
                    CrumbStackSegs: [],
                    Shape: 'Form',
                    Columns: columnsDrilldown,
                    Row: row,
                    List: props.DataAnchor.Id ?? ''
                };
                props.SetStackSegs(prevStackSegs => {
                    return [...prevStackSegs, itemPushed]
                });
            },
            sx: {
              cursor: 'pointer', //you might want to change the cursor too when adding an onClick
            },
        }),        
    });
    return  <MaterialReactTable 
                table={table}
            />;
};

export default ContentList;


